import React from "react"
import Styled, { keyframes } from "styled-components"

const load = keyframes`
    from {
        width: 0%
    }
`
const Progress = Styled.progress`
    background-color: ${({ theme }) => theme.input};
    border: 0;
    height: 2.5rem;
    width: 100%;
    animation: ${load} 2s 0s;
    &::-webkit-progress-bar, &::-moz-progress-bar {
        background-color: ${({ theme }) => theme.input};
    }
    &::-webkit-progress-value {
        background-color: ${({ theme }) => theme.primary};
    }
    box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
`
const Container = Styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const Item = Styled.span`
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    top: -46px;
    padding-left: 4px;
    height: 2.5rem;
    width: 9rem;
    font-size: 1.4rem;
    font-weight: bolder;
    font-family: ${({ theme }) => theme.fontSecondary};
    background-color: ${({ theme }) => theme.primaryVariant};
    color: ${({ theme }) => theme.onSurface};
    z-index: 10;
`
const Percent = Styled.span`
    position: relative;
    margin-right: 4px;
    top: -46px;
    font-size: 1.4rem;
    font-weight: bolder;
    font-family: ${({ theme }) => theme.fontSecondary};
    color: ${({ theme }) => theme.surface};
`

const Skill = ({ skill, value }) => (
  <>
    <Progress value={value} max="100" />
    <Container>
      <Item>{skill}</Item>
      <Percent>{`${value}%`}</Percent>
    </Container>
  </>
)

export default Skill

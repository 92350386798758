import React from "react"
import { graphql } from "gatsby"

import { SKILLS } from "../config"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import RuledBreak from "../components/RuledBreak"
import ProfileImage from "../components/ProfileImage"
import { Container, Skill } from "../components/SkillBar"

const IndexPage = ({ data }) => {
  const headerSecondary = data.secondary.edges[0].node.childImageSharp.fluid
  const adam = data.adam.edges[0].node.childImageSharp.fluid
  return (
    <Layout img={headerSecondary}>
      <SEO
        title="Welcome!"
        keywords={["Kickbush Development", "Kickbush", "Dev"]}
      />
      <div className="row">
        <div className="col-md-10 mr-auto ml-auto py-3 text-center">
          <h4>
            "If you set your goals ridiculously high and it's a failure, you
            will fail above everyone else's success."
          </h4>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 py-3">
          <RuledBreak>About</RuledBreak>
        </div>
      </div>
      <div className="row">
        <div className="col-md-5 mr-auto ml-auto py-3 text-center">
          <ProfileImage fluid={adam} alt="Adam Developer" />
        </div>
        <div className="col-md-5 mr-auto ml-auto py-3">
          <h3 className="text-center">Skills</h3>
          <Container>
            {SKILLS.map(item => (
              <Skill key={item.skill} skill={item.skill} value={item.value} />
            ))}
          </Container>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query indexQuery {
    secondary: allFile(filter: { name: { eq: "kd_logo_white_600x600" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
    adam: allFile(filter: { name: { eq: "adam_600x600" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
  }
`

export default IndexPage

import React from "react"
import Img from "gatsby-image"
import Styled from "styled-components"

import { DEVICES } from "../../config"

const ImgContainer = Styled(Img)`
    width: 300px;
    @media all and ${DEVICES.maxMobileL} {
        width: 150px !important;
    }
`

const ProfileImage = ({ fluid, alt }) => (
  <ImgContainer fluid={fluid} al={alt} imgStyle={{ objectFit: "contain" }} />
)

export default ProfileImage

import React from "react"
import Styled from "styled-components"

const Skills = Styled.div`
    padding-right: 30px;
    padding-left: 30px;
`
const Container = ({ children }) => <Skills>{children}</Skills>

export default Container
